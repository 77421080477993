exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clinics-js": () => import("./../../../src/pages/clinics.js" /* webpackChunkName: "component---src-pages-clinics-js" */),
  "component---src-pages-coaching-js": () => import("./../../../src/pages/coaching.js" /* webpackChunkName: "component---src-pages-coaching-js" */),
  "component---src-pages-ecosystem-js": () => import("./../../../src/pages/ecosystem.js" /* webpackChunkName: "component---src-pages-ecosystem-js" */),
  "component---src-pages-fitting-js": () => import("./../../../src/pages/fitting.js" /* webpackChunkName: "component---src-pages-fitting-js" */),
  "component---src-pages-guiding-js": () => import("./../../../src/pages/guiding.js" /* webpackChunkName: "component---src-pages-guiding-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-juniors-js": () => import("./../../../src/pages/juniors.js" /* webpackChunkName: "component---src-pages-juniors-js" */),
  "component---src-pages-lessons-js": () => import("./../../../src/pages/lessons.js" /* webpackChunkName: "component---src-pages-lessons-js" */),
  "component---src-pages-reform-js": () => import("./../../../src/pages/reform.js" /* webpackChunkName: "component---src-pages-reform-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-shop-cancel-js": () => import("./../../../src/pages/shop/cancel.js" /* webpackChunkName: "component---src-pages-shop-cancel-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-shop-success-js": () => import("./../../../src/pages/shop/success.js" /* webpackChunkName: "component---src-pages-shop-success-js" */)
}

